import {
    AssemblyIndustry,
    AssemblyTypeEnumRuntype,
    AvailabilityStatusEnumRuntype,
    BareIpnIdentifierRuntype,
    BomItemApprovalStatus,
    ComplianceStatus,
    EmissionsDataSummaryRuntype,
    isOtsComponentFull,
    LifecycleEnum,
    QuantityUnitDTORuntype,
} from '@luminovo/http-client';
import * as React from 'react';
import * as r from 'runtypes';
import { BomItem, BomItemOriginRuntype } from '../../../../resources/designItem/bomItemFrontendTypes';
import { assertUnreachable, runtypeFromEnum } from '../../../../utils/typingUtils';
import { FilterId } from './filters';

export type BomItemTableData = BomItem & {
    moduleType: 'bomItem';
    rfqId?: string;
    filterIds: Set<FilterId>;
};

export interface AssemblyTableData extends r.Static<typeof AssemblyTableDataRuntype> {}

const AssemblyTableDataRuntype = r.Record({
    notes: r.String,
    approvalStatus: runtypeFromEnum(BomItemApprovalStatus),
    quantity: QuantityUnitDTORuntype,
    origin: BomItemOriginRuntype,
    moduleType: r.Literal('assembly'),
    rfqId: r.String.nullable(),
    id: r.String,
    designator: r.String,
    type: AssemblyTypeEnumRuntype,
    subAssemblies: r.Array(r.String),
    designItems: r.Array(r.String),
    reachCompliant: runtypeFromEnum(ComplianceStatus).optional(),
    rohsCompliant: runtypeFromEnum(ComplianceStatus).optional(),
    aecqCompliant: runtypeFromEnum(ComplianceStatus).optional(),
    lifecycleStatus: runtypeFromEnum(LifecycleEnum).optional(),
    availability: AvailabilityStatusEnumRuntype.nullable(),
    yearsToEndOfLife: r.Number.nullable(),
    ipn: BareIpnIdentifierRuntype.nullable(),
    emissionData: EmissionsDataSummaryRuntype.nullable(),
});

/**
 * Use isAssemblyTableData and isBomItemTableData to switch on the 2 types
 */
export type ModuleTableData = AssemblyTableData | BomItemTableData;

export function isAssemblyTableData(rowData: ModuleTableData): rowData is AssemblyTableData {
    return rowData.moduleType === 'assembly';
}

export function isBomItemTableData(rowData: ModuleTableData): rowData is BomItemTableData {
    return rowData.moduleType === 'bomItem';
}

export function hasUnresolvedComments(rowData: ModuleTableData) {
    if (isBomItemTableData(rowData)) {
        const eventSummaries = rowData.individualDesignItems.flatMap((designItem) => designItem.event_summary);
        return eventSummaries.some((event) => event.unresolved > 0);
    } else {
        return false;
    }
}

export function hasComments(rowData: ModuleTableData) {
    if (isBomItemTableData(rowData)) {
        const eventSummaries = rowData.individualDesignItems.flatMap((designItem) => designItem.event_summary);
        return eventSummaries.some((event) => event.total > 0);
    } else {
        return false;
    }
}

export function hasResolvedComments(rowData: ModuleTableData) {
    return hasComments(rowData) && !hasUnresolvedComments(rowData);
}

export function hasIpns(rowData: ModuleTableData) {
    if (isBomItemTableData(rowData)) {
        return rowData.approvedPartOptions.filter(isOtsComponentFull).length > 0;
    } else {
        return false;
    }
}

export function hasCpns(rowData: ModuleTableData) {
    if (isBomItemTableData(rowData)) {
        return (
            rowData.approvedPartOptions.filter((part) => {
                return isOtsComponentFull(part) && part.cpns.length > 0;
            }).length > 0
        );
    } else {
        return false;
    }
}

export type ViewContext =
    | {
          type: 'WithinRfQ';
          rfqId: string;
      }
    | {
          type: 'AssemblyOverview';
          // if we are viewing the assembly from the assembly overview pages and its linked to an RFQ, then the rfqId will be present
          rfqId?: string;
      };

export type BomOverviewTableContext = {
    assemblyId: string;
    assemblyIndustry: AssemblyIndustry;
    viewContext: ViewContext;
};

export const groupAndCountFilters = (items: ModuleTableData[]) => {
    const result = new Map<FilterId, number>();
    for (const item of items) {
        if (isBomItemTableData(item)) {
            for (const filterId of item.filterIds) {
                const count = result.get(filterId) ?? 0;
                result.set(filterId, count + 1);
            }
        }
    }
    return result;
};

const doesBomItemHaveAnyOfTheseFilters = (bomItem: BomItemTableData, filterIds: Set<FilterId>) => {
    if (filterIds.size === 0) return true;
    for (const filterId of filterIds) {
        if (bomItem.filterIds.has(filterId)) {
            return true;
        }
    }
    return false;
};

export const useFilterModulesByAppliedFilters = (modules: ModuleTableData[], appliedFilters: Set<FilterId>) => {
    return React.useMemo(() => {
        return modules.filter((module) => {
            if (appliedFilters.size === 0) {
                return true;
            }
            if (isBomItemTableData(module)) {
                return doesBomItemHaveAnyOfTheseFilters(module, appliedFilters);
            }
            if (isAssemblyTableData(module)) {
                return false;
            }
            return assertUnreachable(module);
        });
    }, [modules, appliedFilters]);
};

export function groupModuleTableDataByStatus(
    tableData: ModuleTableData[],
): Record<BomItemApprovalStatus, ModuleTableData[]> {
    const result: Record<BomItemApprovalStatus, ModuleTableData[]> = {
        [BomItemApprovalStatus.DNP]: [],
        [BomItemApprovalStatus.Approved]: [],
        [BomItemApprovalStatus.Pending]: [],
        [BomItemApprovalStatus.Rejected]: [],
        [BomItemApprovalStatus.Warning]: [],
    };
    for (const data of tableData) {
        result[data.approvalStatus].push(data);
    }
    return result;
}

import { Trans } from '@lingui/macro';
import { assertPresent, transEnum } from '@luminovo/commons';
import {
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Flexbox,
    PrimaryButton,
    SecondaryButton,
    TertiaryIconButton,
    Text,
    colorSystem,
} from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { AutoAwesomeRounded, Close as CloseIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { capabilitiesTranslations } from '../../../../resources/pcb/i18n';
import {
    Capability,
    PcbCapabilitiesType,
    STACK_UP_ADVANCED_VALUE_FIELDS,
    STACK_UP_VALUE_FIELDS,
    isPcbApproved,
} from '../../../../resources/pcb/pcbFunctions';
import { getUnit } from '../PcbFormItem/pcbFormItemUtils';
import { useExtractAndSavePcbSpecificationFromPdf } from './utils/usePcbPdfAnalysis/useExtractAndSavePcbSpecificationFromPdf';
import { useShowPcbPdfAnalysisMessageModal } from './utils/useShowPcbPdfAnalysisMessageModal';

export const PcbPdfExtractionModal = ({
    pcb,
    extractedPcbCapabilities,
    handleClose: onConfirmApplication,
}: {
    pcb: PCBV2;
    handleClose?: (value: boolean) => void;
    extractedPcbCapabilities: PcbCapabilitiesType;
}) => {
    // Main hook to extract values from PDF
    const { hasExtractedValues } = useExtractAndSavePcbSpecificationFromPdf({ pcbId: pcb.id });
    const isApproved = isPcbApproved(pcb);

    const [showMessageModal, setShowMessageModal] = React.useState(false);
    const { userHasSeenModal, setUserHasSeenModal, setUserAgreesToExtraction } = useShowPcbPdfAnalysisMessageModal(
        pcb.id,
    );

    const { stackUpValues, boardValues } = extractedPcbCapabilities.fields.reduce<{
        stackUpValues: Capability[];
        boardValues: Capability[];
    }>(
        (acc, capability) => {
            if ([...STACK_UP_ADVANCED_VALUE_FIELDS, ...STACK_UP_VALUE_FIELDS].includes(capability.capabilityName)) {
                acc.stackUpValues.push(capability);
            } else {
                acc.boardValues.push(capability);
            }
            return acc;
        },
        {
            stackUpValues: [],
            boardValues: [],
        },
    );

    React.useEffect(() => {
        const showExtractionModal = () => {
            const timer = setTimeout(() => {
                setShowMessageModal(true);
            }, 1000);
            return () => clearTimeout(timer);
        };

        if (userHasSeenModal === false && hasExtractedValues && isApproved === false) {
            showExtractionModal();
        }
    }, [hasExtractedValues, userHasSeenModal, setUserHasSeenModal, isApproved]);

    const handleAcceptClick = () => {
        setShowMessageModal(false);
        setUserAgreesToExtraction(true);
        setUserHasSeenModal(true);
        onConfirmApplication?.(true);
    };

    const handleRejectClick = () => {
        setShowMessageModal(false);
        setUserAgreesToExtraction(false);
        setUserHasSeenModal(true);
        onConfirmApplication?.(false);
    };

    return (
        <Dialog open={showMessageModal} maxWidth={'xs'}>
            <Box
                style={{
                    background: `linear-gradient(273deg, #4B54B0 0%, #6E7EF7 100%)`,
                    height: '50px',
                    padding: '32px',
                    flexDirection: 'column',
                    color: colorSystem.neutral.white,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    gap: '16px',
                }}
            >
                <AutoAwesomeRounded />
                <Text variant="h2" style={{ color: colorSystem.neutral.white }}>
                    <Trans>PCB specification values detected</Trans>
                </Text>
                <TertiaryIconButton
                    style={{
                        color: colorSystem.neutral.white,
                        position: 'absolute',
                        right: '8px',
                        top: '8px',
                        padding: 0,
                    }}
                    onClick={handleRejectClick}
                >
                    <CloseIcon />
                </TertiaryIconButton>
            </Box>
            <DialogContent>
                <Flexbox flexDirection={'column'} gap={'16px'}>
                    <Text>
                        <Trans>
                            You've uploaded a PDF containing additional PCB specifications. Our AI is trained to detect
                            and extract PCB specifications from PDF documents. Would you like to apply the extracted
                            values to your specification?
                        </Trans>
                    </Text>
                    {boardValues.length > 0 && (
                        <>
                            <Text variant="body-semibold">
                                <Trans>PCB specification values</Trans>
                            </Text>
                            <Flexbox gap={'4px'} flexWrap={'wrap'}>
                                {boardValues.map((capability) => (
                                    <DisplayChip key={capability.capabilityName} capability={capability} />
                                ))}
                            </Flexbox>
                        </>
                    )}

                    {stackUpValues.length > 0 && (
                        <>
                            <Text variant="body-semibold">
                                <Trans>Stackup values</Trans>
                            </Text>
                            <Flexbox gap={'4px'} flexWrap={'wrap'}>
                                {stackUpValues.map((capability) => (
                                    <DisplayChip key={capability.capabilityName} capability={capability} />
                                ))}
                            </Flexbox>
                        </>
                    )}
                </Flexbox>
            </DialogContent>
            <DialogActions>
                <SecondaryButton size="medium" onClick={handleRejectClick}>
                    <Trans>Don't apply</Trans>
                </SecondaryButton>
                <PrimaryButton size="medium" onClick={handleAcceptClick}>
                    <Trans>Apply</Trans>
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

const DisplayChip = ({ capability }: { capability: Capability }) => {
    return (
        <Chip
            color="neutral"
            key={capability.capabilityName}
            label={
                <>
                    {transEnum(capability.capabilityName, capabilitiesTranslations)} |
                    <strong>
                        {(assertPresent(capability.extractedFromPdf?.value) as string).toString()}{' '}
                        {getUnit(capability.name)}
                    </strong>
                </>
            }
        />
    );
};

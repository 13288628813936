import { t } from '@lingui/macro';
import { assertUnreachable, formatMonetaryValue, isPresent } from '@luminovo/commons';
import { Flexbox, Tag, TagProps } from '@luminovo/design-system';
import { AdditionalSolutionCostDTO, CostsFromRulesDTO } from '@luminovo/http-client';

type CostType = 'packaging' | 'discount' | 'shipping' | 'customs' | 'other';

const getColor = (costType: CostType): TagProps['color'] => {
    switch (costType) {
        case 'packaging':
            return 'violet';
        case 'discount':
            return 'yellow';
        case 'shipping':
            return 'blue';
        case 'customs':
            return 'green';
        case 'other':
            return 'neutral';
        default:
            assertUnreachable(costType);
    }
};

const getLabel = (costType: CostType): string => {
    switch (costType) {
        case 'packaging':
            return t`Packaging`;
        case 'discount':
            return t`Discount`;
        case 'shipping':
            return t`Shipping`;
        case 'customs':
            return t`Customs`;
        case 'other':
            return t`Other`;
        default:
            assertUnreachable(costType);
    }
};

const CostTag = ({ costType }: { costType: CostType }): JSX.Element => {
    const color = getColor(costType);
    const label = getLabel(costType);

    return <Tag color={color} label={label} attention="low" />;
};

const CostRow = ({ cost, costType }: { cost: CostsFromRulesDTO | undefined; costType: CostType }): JSX.Element => {
    return (
        <Flexbox justifyContent="space-between" minWidth={200}>
            <CostTag costType={costType} />
            {isPresent(cost) ? `${formatMonetaryValue(cost.cost)} ${t`per unit`}` : '-'}
        </Flexbox>
    );
};

export const AdditionalCostTooltip = ({
    additionalCost,
}: {
    additionalCost: AdditionalSolutionCostDTO | undefined;
}): JSX.Element => {
    return (
        <Flexbox flexDirection="column" gap={16} padding={1}>
            <CostRow cost={additionalCost?.breakdown.packaging_cost ?? undefined} costType="packaging" />
            <CostRow cost={additionalCost?.breakdown.discount ?? undefined} costType="discount" />
            <CostRow cost={additionalCost?.breakdown.customs_cost ?? undefined} costType="customs" />
            <CostRow cost={additionalCost?.breakdown.shipping_cost ?? undefined} costType="shipping" />
            <CostRow cost={additionalCost?.breakdown.other_cost ?? undefined} costType="other" />
        </Flexbox>
    );
};

import { t, Trans } from '@lingui/macro';
import { assertUnreachable, isPresent, isProductionEnvironment } from '@luminovo/commons';
import {
    Chip,
    colorSystem,
    FieldNumericControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    SecondaryButton,
    Text,
    TextField,
} from '@luminovo/design-system';
import { Cancel } from '@mui/icons-material';
import { Autocomplete, Box, CircularProgress } from '@mui/material';

import { AssemblyFormDTO, UserType } from '@luminovo/http-client';
import * as React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useUserType } from '../../../components/contexts/CurrentUserDetailsContext';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { LoadingText } from '../../../components/Spinners';
import { TextBreadcrumbWithAssemblyId, useAllBreadcrumbs } from '../../../resources/breadcrumbs/breadcrumbsHandler';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { FormItemAssemblyTypeRadio } from './FieldAssemblyTypeRadioControlled';

export function AssemblyForm({
    onSubmit,
    defaultValues,
    disableAssemblyType = false,
    assemblyId,
}: {
    onSubmit: (form: AssemblyFormDTO) => Promise<void>;
    defaultValues: AssemblyFormDTO;
    disableAssemblyType?: boolean;
    assemblyId?: string;
}) {
    const validationErrors: ValidationErrors<AssemblyFormDTO> = {
        'assembly.cannot_add_top_level_assembly_as_customer_user': {
            fieldPath: 'parents',
        },
    };

    const isProdEnv = isProductionEnvironment();

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <Flexbox gap={32} flexDirection="column">
                <FormSection title={t`Assembly info`}>
                    <FormItemParentAssembly assemblyId={assemblyId} />
                    <FormItemName />
                    <FormItemIpn />
                    {!disableAssemblyType && <FormItemAssemblyType />}
                    {!isProdEnv && <FormSubAssemblyQuantities />}
                    <FormItemNotes />
                </FormSection>
                <Flexbox flexDirection="row" gap={8} justifyContent="flex-end" marginTop={4}>
                    <CancelButton />
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function FormSubAssemblyQuantities() {
    const { control } = useFormContext<AssemblyFormDTO>();

    const subAssemblies = useWatch({ name: 'sub_assembly_quantities', control });

    const subAssemblyIds = React.useMemo(() => {
        return subAssemblies.map((data) => data.assembly_id);
    }, [subAssemblies]);

    const { data } = useHttpQuery(
        'POST /assemblies/bulk',
        { requestBody: { ids: subAssemblyIds }, queryParams: { with_aggregations: false } },
        {
            enabled: subAssemblyIds.length > 0,
            select: (data) => {
                return data.items.reduce(
                    (acc, assembly) => {
                        acc[assembly.id] = assembly.designator;
                        return acc;
                    },
                    {} as Record<string, string>,
                );
            },
        },
    );

    const { fields } = useFieldArray({
        control,
        name: 'sub_assembly_quantities',
    });
    if (subAssemblies.length === 0) return null;

    if (!isPresent(data)) {
        return <CircularProgress />;
    }

    return (
        <FormItem label={t`Subassembly quantities`}>
            {fields.map((field, index) => {
                return (
                    <Flexbox key={field.id} alignItems={'center'} gap={8}>
                        <FieldNumericControlled
                            min={1}
                            control={control}
                            name={`sub_assembly_quantities.${index}.quantity`}
                            required={true}
                            isInteger={true}
                            FieldProps={{ style: { width: 50 }, placeholder: t`Quantity` }}
                        />

                        <Text>{data[subAssemblies[index].assembly_id] ?? t`Unknown`}</Text>
                    </Flexbox>
                );
            })}
        </FormItem>
    );
}

function CancelButton() {
    const history = useHistory();
    const handleClose = React.useCallback(() => {
        history.goBack();
    }, [history]);
    return (
        <SecondaryButton onClick={handleClose}>
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}

type BreadCrumbMap = { [key: string]: TextBreadcrumbWithAssemblyId };

function FormItemParentAssembly({ assemblyId }: { assemblyId?: string }) {
    const {
        control,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useFormContext<AssemblyFormDTO>();

    const rfqId = useWatch({ name: 'rfq', control });
    const userType = useUserType();

    const parents = useWatch({ name: 'parents', control });

    const { breadcrumbs = [], isLoading } = useAllBreadcrumbs(rfqId);
    const { data: assemblyDescendants = [], isLoading: areAssemblyDescendantsLoading } = useHttpQuery(
        'GET /assemblies/:assemblyId/assembly-descendants',
        { pathParams: { assemblyId: assemblyId ?? '' } },
        {
            enabled: Boolean(assemblyId),
            select: (data) => {
                return data.items.map((item) => item.id);
            },
        },
    );
    const isSuccess = isPresent(breadcrumbs) && !isLoading && !areAssemblyDescendantsLoading && breadcrumbs.length > 0;

    const options = React.useMemo(() => {
        return filterBreadcrumbs(breadcrumbs, userType, rfqId).map((breadcrumb) => breadcrumb.id);
    }, [breadcrumbs, userType, rfqId]);

    const breadCrumbMap: BreadCrumbMap = React.useMemo(() => {
        return breadcrumbs.reduce((acc, crumb) => {
            acc[crumb.id] = crumb;
            return acc;
        }, {} as BreadCrumbMap);
    }, [breadcrumbs]);

    React.useEffect(() => {
        if (parents.length === 0) {
            setError('parents', {
                type: 'required',
                message: t`Required`,
            });
        } else {
            clearErrors('parents');
        }
    }, [parents.length, setError, clearErrors]);

    const removeParentAssembly = React.useCallback(
        (parentId: string) => {
            setValue(
                'parents',
                parents.filter((parent) => parent !== parentId),
            );
        },
        [setValue, parents],
    );

    // once the other teams adapt their domains, we should change this to accept a list of parentIds
    // and add the multiple flag on the AutoComplete component
    const addParentAssembly = (parentData: string) => {
        setValue('parents', [parentData]);
    };

    return (
        <FormItem label={t`Parent assembly`} required>
            {!isSuccess && <LoadingText />}
            {isSuccess && (
                <>
                    <Autocomplete
                        value={parents[0]}
                        size="small"
                        getOptionLabel={(option) => breadCrumbMap[option]?.breadcrumb ?? t`Unknown`}
                        getOptionDisabled={(option) => {
                            return (
                                option === assemblyId ||
                                assemblyDescendants.includes(option) ||
                                parents.includes(option)
                            );
                        }}
                        renderTags={(value, getTagProps) => {
                            return value.map((val, index) => {
                                const data = breadCrumbMap[val];
                                const breadCrumb = data?.breadcrumb ?? t`Loading`;
                                return (
                                    <Box marginRight="4px" key={index}>
                                        <Chip
                                            color="neutral"
                                            {...getTagProps({ index })}
                                            label={breadCrumb}
                                            deleteIcon={<Cancel style={{ color: colorSystem.primary[7] }} />}
                                            onDelete={() => {
                                                removeParentAssembly(val);
                                            }}
                                        />
                                    </Box>
                                );
                            });
                        }}
                        options={options}
                        renderInput={(params): JSX.Element => (
                            <TextField
                                {...params}
                                size="small"
                                error={'parents' in errors}
                                helperText={errors.parents?.message}
                            />
                        )}
                        onChange={(_, value) => {
                            if (value) {
                                addParentAssembly(value);
                            }
                        }}
                    />
                </>
            )}
        </FormItem>
    );
}

/* In the customer portal we don't allow users to add multiple top-level assemblies  */
const filterBreadcrumbs = (breadCrumbs: TextBreadcrumbWithAssemblyId[], userType: UserType, rfqId: string) => {
    switch (userType) {
        case UserType.Customer:
            return breadCrumbs.filter((breadcrumb) => breadcrumb.id !== rfqId);
        case UserType.Supplier:
        case UserType.Internal:
            return breadCrumbs;
        default:
            assertUnreachable(userType);
    }
};

function FormItemName() {
    const { control } = useFormContext<AssemblyFormDTO>();
    return (
        <FormItem label={t`Assembly name`} required>
            <FieldTextControlled control={control} name="designator" required />
        </FormItem>
    );
}

function FormItemIpn() {
    return (
        <Flexbox flexDirection="row" gap={8}>
            <FormItemIpnValue />
            <FormItemIpnRevision />
        </Flexbox>
    );
}

function FormItemIpnValue() {
    const { control } = useFormContext<AssemblyFormDTO>();
    return (
        <FormItem label={t`IPN`} flexGrow={1}>
            {/*eslint-disable-next-line camelcase*/}
            <FieldTextControlled control={control} name="ipn_value" />
        </FormItem>
    );
}

function FormItemIpnRevision() {
    const { control } = useFormContext<AssemblyFormDTO>();
    return (
        <FormItem label={'Revision'} flexGrow={1}>
            {/*eslint-disable-next-line camelcase*/}
            <FieldTextControlled control={control} name="ipn_revision" />
        </FormItem>
    );
}

function FormItemAssemblyType() {
    const { control } = useFormContext<AssemblyFormDTO>();
    return <FormItemAssemblyTypeRadio label={t`Type`} name="type.type" control={control} required />;
}

function FormItemNotes() {
    const { control } = useFormContext<AssemblyFormDTO>();
    return (
        <FormItem label={t`Notes`}>
            <FieldTextControlled control={control} name="notes" FieldProps={{ multiline: true, minRows: 2 }} />
        </FormItem>
    );
}

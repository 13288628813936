import { plural, t, Trans } from '@lingui/macro';
import { isPresent, transEnum } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    DEFAULT_EXPAND_COLUMN_ID,
    Flexbox,
    StatusChip,
    Tag,
    TertiaryIconButton,
    Text,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    AvailabilityStatusEnum,
    ComplianceStatus,
    CustomerPartNumber,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    LifecycleEnum,
    PartCountEnum,
    PartSpecificationTypes,
} from '@luminovo/http-client';
import {
    ComplianceStatusChip,
    ComplianceStatusTranslations,
    lifecycleEnumPublicTranslations,
} from '@luminovo/sourcing-core';
import { ChatOutlined, Info, MarkUnreadChatAltOutlined } from '@mui/icons-material';
import { ColumnFiltersState, Row, Table } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router';
import { useCommunicationsDrawer } from '../../../../../components/CommentsDrawer';
import { PartOptionAvailabilityView } from '../../../../../components/PartAvailabilityView';
import { CpnView } from '../../../../../components/partColumns';
import { EmissionsTooltip } from '../../../../../components/PartEmissionsView/EmissionsTooltip';
import { EmissionsView } from '../../../../../components/PartEmissionsView/EmissionsView';
import { findPartEmissions } from '../../../../../components/PartEmissionsView/findMinMaxEmissions';
import { PartEmissionsView } from '../../../../../components/PartEmissionsView/PartEmissionsView';
import { PcbEmissionsView } from '../../../../../components/PartEmissionsView/PcbEmissionsView';
import { LifecycleChipWithTooltip } from '../../../../../components/PartLifecycleView';
import { getSpecificAssemblyPath } from '../../../../../resources/assembly/assemblyHandler';
import { isPcbDesignItem } from '../../../../../resources/designItem/designItemFunctions';
import { CommentsEnum, CommentsTranslations } from '../../../../../resources/designItem/i18n';
import { AvailabilityStatusPublicTranslations, partCountTranslationsLong } from '../../../../../resources/part/i18n';
import { assemblyTypePublicTranslations } from '../../../../../resources/rfq/i18n';
import { route } from '../../../../../utils/routes';
import { alphaNumericalStringSort } from '../../../../../utils/stringFunctions';
import { formatIpnIdentifier } from '../../../../AssemblyOverview/AssemblyOverviewTable';
import {
    AssemblyTableData,
    BomItemTableData,
    hasComments,
    hasResolvedComments,
    hasUnresolvedComments,
    isAssemblyTableData,
    isBomItemTableData,
    ModuleTableData,
    ViewContext,
} from '../../ModuleTableData';
import { FilterId } from '../../ModuleTableData/filters';
import {
    ApprovedPartOptionItem,
    bomOverviewColumnIds,
    BomOverviewItem,
    BomOverviewSharedContext,
    BomOverviewTableData,
} from './bomOverviewFrontendTypes';
import { EmptyValue } from './columns/EmptyValue';
import { GenericLabel } from './columns/GenericLabel';
import { RenderAvailability } from './columns/RenderAvailability';
import { findUniquePartCategories, RenderPartCategory } from './columns/RenderPartCategory';
import { RenderPartLabel } from './columns/RenderPartLabel';

export const dashboardWidth = '1680px';

function findRedirectRoute({
    assemblyId,
    moduleTableData: data,
    viewContext,
    urlSearch,
}: {
    assemblyId: string;
    moduleTableData: ModuleTableData;
    viewContext: ViewContext;
    urlSearch?: string;
}) {
    if (isAssemblyTableData(data)) {
        return viewContext.type === 'WithinRfQ'
            ? getSpecificAssemblyPath({
                  rfqId: viewContext.rfqId,
                  assemblyId: data.id,
                  currentParentAssemblyId: assemblyId,
              })
            : route(
                  '/assemblies/:assemblyId/dashboard',
                  { assemblyId: data.id },
                  { rfqId: data.rfqId, isMonitoringOpen: undefined, tab: undefined },
              );
    }
    if (viewContext.type === 'AssemblyOverview') {
        return route(
            '/assemblies/:assemblyId/details',
            { assemblyId },
            {
                designItemId: data.id.join(','),
                filters: null,
                dashboardFilters: null,
                search: null,
            },
        );
    }
    const rfqId = viewContext.rfqId;
    return route(
        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
        {
            rfqId,
            assemblyId,
        },
        {
            isReadonly: 'true',
            designItemId: data.id.join(','),
            bomTab: null,
            filters: urlSearch ? FilterId.HealthDashboardFilters.toString() : null,
            dashboardFilters: urlSearch ? urlSearch : null,
            search: null,
            currentParentAssemblyId: rfqId,
            onlyShowItemsWithManufacturingWarnings: null,
        },
    );
}

function serialiseColumnFilters({ columnFilters }: { columnFilters: ColumnFiltersState }): string {
    if (columnFilters.length === 0) {
        return '';
    }
    return JSON.stringify(columnFilters);
}

export function useBomOverviewTable({
    items,
    isBomMainPage,
    assemblyId,
    viewContext,
    columnFilters,
}: {
    items: ModuleTableData[] | undefined;
    isBomMainPage: boolean;
    assemblyId: string;
    viewContext: ViewContext;
    columnFilters?: ColumnFiltersState;
}) {
    const history = useHistory();

    const columns = React.useMemo(() => {
        return isBomMainPage
            ? [columnName, columnDetails, columnAvailability, columnReach, columnRohs, columnAecq, columnLifecycle]
            : [
                  columnDesignators,
                  columnIpn,
                  columnCpn,
                  columnPartCategory,
                  columnPartOptions,
                  columnAvailability,
                  columnReach,
                  columnRohs,
                  columnAecq,
                  columnLifecycle,
                  columnYearsToEndOfLife,
                  columnEmissions,
                  columnComments,
              ];
    }, [isBomMainPage]);

    const data: (BomOverviewItem | ApprovedPartOptionItem)[] | undefined = useMemo(() => {
        return items?.map((moduleItem) => ({
            type: 'row',
            moduleTableData: moduleItem,
            approvedPartOptions: isBomItemTableData(moduleItem)
                ? moduleItem.approvedPartOptions.map((part): ApprovedPartOptionItem => {
                      return { type: 'subrow', fullPart: part };
                  })
                : [],
        }));
    }, [items]);

    const sharedContext: BomOverviewSharedContext = useMemo(() => ({ assemblyId }), [assemblyId]);

    const handleItemClick = React.useCallback(
        (row: Row<BomOverviewTableData>, table: Table<BomOverviewTableData>) => {
            const columnFilters = table.getState().columnFilters;
            const serialisedFilters = serialiseColumnFilters({ columnFilters });

            if (row.original.type === 'row') {
                return history.push(
                    findRedirectRoute({
                        assemblyId,
                        moduleTableData: row.original.moduleTableData,
                        viewContext,
                        urlSearch: serialisedFilters,
                    }),
                );
            } else {
                const parentRow = row.getParentRow()?.original;
                return parentRow?.type === 'row'
                    ? history.push(
                          findRedirectRoute({
                              assemblyId,
                              moduleTableData: parentRow.moduleTableData,
                              viewContext,
                              urlSearch: serialisedFilters,
                          }),
                      )
                    : null;
            }
        },
        [history, assemblyId, viewContext],
    );

    return useTanStackTable<BomOverviewTableData, BomOverviewSharedContext>({
        data,
        columns,
        sharedContext,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enableSaveAsDefault: `bomOverviewTable:${assemblyId}`,
        enablePersistentScrollPosition: true,
        enableExcelExport: true,
        initialState: {
            columnPinning: {
                left: [DEFAULT_EXPAND_COLUMN_ID],
                right: [bomOverviewColumnIds.columnComments],
            },
            columnFilters,
        },
        onRowClick: handleItemClick,
        getSubRows: isBomMainPage
            ? undefined
            : (row) => (row.type === 'row' && row.approvedPartOptions.length > 1 ? row.approvedPartOptions : []),
    });
}

const columnHelper = createColumnHelper<BomOverviewItem | ApprovedPartOptionItem, BomOverviewSharedContext>();

const RenderDesignators = ({ row }: { row: Row<BomOverviewItem | ApprovedPartOptionItem> }) => {
    if (row.original.type === 'row') {
        const designator = row.original.moduleTableData.designator;

        if (typeof designator === 'string') {
            return <GenericLabel name={designator} />;
        }
        const designatorsToDisplay = Array.from(designator).sort(alphaNumericalStringSort).join(', ');
        if (designatorsToDisplay.length === 0) {
            return <Tag color="red" attention="low" label={t`No designators`} />;
        }
        return <GenericLabel name={designatorsToDisplay} />;
    }
    return <></>;
};

const columnDesignators = columnHelper.text(
    (row) => {
        if (row.type === 'row') {
            const moduleTableData = row.moduleTableData;
            return isBomItemTableData(moduleTableData) ? moduleTableData.designator.join(', ') : null;
        }
    },
    {
        id: bomOverviewColumnIds.columnDesignators,
        label: () => t`Designator`,
        size: 140,
        cell: RenderDesignators,
    },
);

// Renders the same as columnDesignators but displays a different header in the BOM main page
const columnName = columnHelper.text(
    (row) => {
        if (row.type === 'row') {
            const moduleTableData = row.moduleTableData;
            return isAssemblyTableData(moduleTableData) ? moduleTableData.designator : null;
        }
    },
    {
        id: 'columnName',
        label: () => t`Name`,
        size: 160,
        cell: RenderDesignators,
    },
);

function formatIpnInModuleTableData(moduleData: ModuleTableData): string {
    const maximumIpnsToShow = 1;
    if (isAssemblyTableData(moduleData)) {
        return formatIpnIdentifier(moduleData.ipn);
    }
    if (isBomItemTableData(moduleData)) {
        const ipns = moduleData.approvedPartOptions
            .map((part) => {
                if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
                    return part.id;
                }
                return null;
            })
            .filter(isPresent);

        return ipns.length === 0
            ? '-'
            : ipns.length === 1
              ? ipns[0]
              : `${Array.from(ipns).slice(0, maximumIpnsToShow).join(', ')} ${
                    ipns.length > maximumIpnsToShow && ` +${ipns.length - maximumIpnsToShow}`
                }`;
    }
    return '-';
}

const columnIpn = columnHelper.text(
    (row) => {
        if (row.type === 'row') {
            return formatIpnInModuleTableData(row.moduleTableData);
        }
        if (isOtsComponentFull(row.fullPart)) {
            return row.fullPart.id;
        }
        if (isCustomComponentFull(row.fullPart)) {
            return row.fullPart.id;
        }
        return '-';
    },
    {
        id: bomOverviewColumnIds.columnIpn,
        label: () => t`IPN`,
        size: 140,
        cell: ({ row }) => {
            if (row.original.type === 'row') {
                const ipn = formatIpnInModuleTableData(row.original.moduleTableData);
                return <GenericLabel name={ipn} />;
            }
            if (isOtsComponentFull(row.original.fullPart)) {
                return row.original.fullPart.id;
            }
            if (isCustomComponentFull(row.original.fullPart)) {
                return row.original.fullPart.id;
            }
            return '-';
        },
    },
);

const RenderCpnFromModuleDataTable = ({ moduleData }: { moduleData: ModuleTableData }) => {
    if (isBomItemTableData(moduleData)) {
        const cpns: CustomerPartNumber[] = moduleData.approvedPartOptions
            .flatMap((part) => {
                if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
                    return part.cpns;
                }
                return null;
            })
            .filter(isPresent);

        return (
            <Flexbox maxWidth="100px">
                <Text variant="body-small" showEllipsis style={{ whiteSpace: 'nowrap' }}>
                    {cpns.length > 0 ? cpns[0].value : '-'}
                    {cpns.length > 0 && cpns[0].revision && ` • ${cpns[0].revision}`}
                    {cpns.length > 1 && (
                        <Text style={{ marginLeft: 8, color: colorSystem.neutral[6] }}>+{cpns.length - 1}</Text>
                    )}
                </Text>
            </Flexbox>
        );
    }

    return <EmptyValue />;
};

const columnCpn = columnHelper.text(
    (row) => {
        if (row.type === 'row') {
            return isBomItemTableData(row.moduleTableData)
                ? row.moduleTableData.approvedPartOptions.map((part) => {
                      if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
                          return part.cpns.join(', ');
                      }
                      return null;
                  })
                : null;
        }
    },
    {
        id: bomOverviewColumnIds.columnCpn,
        label: () => t`CPN • Rev`,
        size: 140,
        initialVisibility: false,
        cell: ({ row }) => {
            if (row.original.type === 'row') {
                return <RenderCpnFromModuleDataTable moduleData={row.original.moduleTableData} />;
            } else {
                const partOption = row.original.fullPart;
                if (isCustomComponentFull(partOption) || isOtsComponentFull(partOption)) {
                    return <CpnView key={partOption.id} part={partOption} />;
                }
                return <EmptyValue />;
            }
        },
    },
);

const columnPartCategory = columnHelper.text(
    (row) => {
        if (row.type === 'row') {
            const uniqPartCategories: string[] = isBomItemTableData(row.moduleTableData)
                ? findUniquePartCategories({ parts: row.moduleTableData.approvedPartOptions })
                : [];
            if (uniqPartCategories.length === 1) {
                return uniqPartCategories[0];
            }
            if (uniqPartCategories.length > 1) {
                return <Trans>Diverse</Trans>;
            }
            return <>-</>;
        }
    },
    {
        id: bomOverviewColumnIds.columnPartCategory,
        label: () => t`Part category`,
        size: 140,
        cell: ({ row }) => {
            if (row.original.type === 'row') {
                if (isBomItemTableData(row.original.moduleTableData)) {
                    return <RenderPartCategory parts={row.original.moduleTableData.approvedPartOptions} />;
                }
                return <EmptyValue />;
            } else {
                return <RenderPartCategory parts={[row.original.fullPart]} />;
            }
        },
    },
);

const getAssemblyDetails = ({ assemblyTableData }: { assemblyTableData: AssemblyTableData }): string => {
    const subAssembliesCount = assemblyTableData.subAssemblies.length;
    const designItemsCount = assemblyTableData.designItems.length;

    const subAssembliesText = `${plural(subAssembliesCount, {
        one: `with ${subAssembliesCount} subassembly`,
        other: `with ${subAssembliesCount} subassemblies`,
    })}`;

    const designItemsText = `${plural(designItemsCount, {
        one: `${designItemsCount} design item`,
        other: `${designItemsCount} design items`,
    })}`;
    return `${transEnum(assemblyTableData.type, assemblyTypePublicTranslations)} 
                ${subAssembliesCount > 0 ? subAssembliesText : ''} 
                ${subAssembliesCount > 0 && designItemsCount > 0 ? `&` : designItemsCount > 0 ? t`with` : ''} 
                ${designItemsCount > 0 ? designItemsText : ''}`;
};

const RenderPartOptionsCell = ({ moduleTableData }: { moduleTableData: ModuleTableData }): JSX.Element => {
    if (isAssemblyTableData(moduleTableData)) {
        return (
            <Text showEllipsis variant="body-small">
                {getAssemblyDetails({ assemblyTableData: moduleTableData })}
            </Text>
        );
    }

    if (isPcbDesignItem(moduleTableData)) {
        return <GenericLabel name={t`Custom PCB`} />;
    }

    return <PartOptionStatusChip rowData={moduleTableData} />;
};

const PartOptionStatusChip = ({ rowData }: { rowData: BomItemTableData }): JSX.Element => {
    const numberOfApprovedPartOptions = rowData.approvedPartOptions.length;

    if (rowData.doNotPlace) {
        return (
            <Tooltip title={t`BOM item that will not be assembled`}>
                <span>
                    <StatusChip
                        label={transEnum(PartCountEnum.DNP, partCountTranslationsLong)}
                        color={'neutral'}
                        enableHoverEffect
                    />
                </span>
            </Tooltip>
        );
    }

    if (numberOfApprovedPartOptions === 0) {
        return (
            <Tooltip title={t`BOM item with no approved part option`}>
                <span>
                    <StatusChip
                        label={transEnum(PartCountEnum.None, partCountTranslationsLong)}
                        color={'red'}
                        enableHoverEffect
                    />
                </span>
            </Tooltip>
        );
    }

    if (numberOfApprovedPartOptions === 1) {
        return <RenderPartLabel partOption={rowData.approvedPartOptions[0]} />;
    }

    return (
        <Flexbox style={{ whiteSpace: 'nowrap' }} alignItems="center" gap="4px">
            <RenderPartLabel partOption={rowData.approvedPartOptions[0]} />{' '}
            {numberOfApprovedPartOptions > 1 && (
                <Text color={colorSystem.neutral[6]} variant="body-small">
                    +{numberOfApprovedPartOptions - 1}
                </Text>
            )}
        </Flexbox>
    );
};

const columnPartOptions = columnHelper.enum(
    (row) => {
        if (row.type === 'row') {
            return isBomItemTableData(row.moduleTableData)
                ? row.moduleTableData.approvedPartOptionCardinality
                : undefined;
        }
    },
    {
        id: bomOverviewColumnIds.columnPartOptions,
        label: () => t`Part options`,
        size: 300,
        options: Object.values(PartCountEnum),
        getOptionLabel: (opt) => (opt ? transEnum(opt, partCountTranslationsLong) : t`Unknown`),
        cell: ({ row }) => {
            if (row.original.type === 'row') {
                return <RenderPartOptionsCell moduleTableData={row.original.moduleTableData} />;
            } else {
                return <RenderPartLabel partOption={row.original.fullPart} />;
            }
        },
    },
);

// Renders the same as columnPartOptions but displays a different header in the BOM main page
const columnDetails = columnHelper.text(
    (row) => {
        if (row.type === 'row') {
            return isAssemblyTableData(row.moduleTableData)
                ? getAssemblyDetails({ assemblyTableData: row.moduleTableData })
                : undefined;
        }
    },
    {
        id: 'columnDetails',
        label: () => t`Details`,
        size: 180,
        cell: ({ row }) => {
            if (row.original.type === 'row') {
                return <RenderPartOptionsCell moduleTableData={row.original.moduleTableData} />;
            } else {
                return <RenderPartLabel partOption={row.original.fullPart} />;
            }
        },
    },
);

const columnAvailability = columnHelper.enum(
    (row) => {
        if (row.type === 'row') {
            if (isAssemblyTableData(row.moduleTableData)) {
                return row.moduleTableData.availability;
            }

            return isBomItemTableData(row.moduleTableData)
                ? (row.moduleTableData.availability?.type ?? undefined)
                : undefined;
        }
    },
    {
        id: bomOverviewColumnIds.columnAvailability,
        label: () => t`Availability`,
        size: 120,
        options: Object.values(AvailabilityStatusEnum),
        getOptionLabel: (opt) => (opt ? transEnum(opt, AvailabilityStatusPublicTranslations) : t`Unknown`),
        cell: ({ row, sharedContext }) => {
            if (row.original.type === 'row') {
                return <RenderAvailability moduleTableData={row.original.moduleTableData} />;
            } else {
                const parent = row.getParentRow()?.original;
                if (parent?.type === 'row' && isBomItemTableData(parent.moduleTableData)) {
                    const bomItem = parent.moduleTableData;
                    const part = row.original.fullPart;

                    return bomItem.doNotPlace ||
                        bomItem.isConsigned ||
                        bomItem.specification?.type === PartSpecificationTypes.Custom ||
                        !bomItem.rfqId ? (
                        <EmptyValue />
                    ) : (
                        <PartOptionAvailabilityView
                            bomItem={bomItem}
                            part={part}
                            rfqId={bomItem.rfqId}
                            assemblyId={sharedContext.assemblyId}
                        />
                    );
                }
            }
        },
    },
);

function extractModuleTableDataReach(moduleTableData: ModuleTableData) {
    if (isAssemblyTableData(moduleTableData)) {
        return moduleTableData.reachCompliant;
    }
    if (isPcbDesignItem(moduleTableData)) {
        return undefined;
    }
    return moduleTableData.approvedPartOptions.length > 0 ? moduleTableData.reachCompliant : undefined;
}

const columnReach = columnHelper.enum(
    (row) => {
        if (row.type === 'row') {
            return extractModuleTableDataReach(row.moduleTableData);
        }
    },
    {
        id: bomOverviewColumnIds.columnReach,
        label: () => t`REACH`,
        size: 120,
        options: Object.values(ComplianceStatus),
        getOptionLabel: (opt) => (opt ? transEnum(opt, ComplianceStatusTranslations) : t`Unknown`),
        cell: ({ row, getValue }) => {
            if (row.original.type === 'row') {
                const value = getValue();
                if (!value) {
                    return <EmptyValue />;
                }
                return <ComplianceStatusChip status={value} />;
            } else {
                const part = row.original.fullPart;
                if (
                    isOtsFullPart(part) ||
                    isGenericFullPart(part) ||
                    isOtsComponentFull(part) ||
                    isCustomFullPart(part)
                ) {
                    return <ComplianceStatusChip status={part.reach_compliant} />;
                }
                return <EmptyValue />;
            }
        },
    },
);

function extractModuleTableDataRohs(moduleTableData: ModuleTableData) {
    if (isAssemblyTableData(moduleTableData)) {
        return moduleTableData.rohsCompliant;
    }
    if (isPcbDesignItem(moduleTableData)) {
        return undefined;
    }
    return moduleTableData.approvedPartOptions.length > 0 ? moduleTableData.rohsCompliant : undefined;
}

const columnRohs = columnHelper.enum(
    (row) => {
        if (row.type === 'row') {
            return extractModuleTableDataRohs(row.moduleTableData);
        }
    },
    {
        id: bomOverviewColumnIds.columnRohs,
        label: () => t`RoHS`,
        size: 120,
        options: Object.values(ComplianceStatus),
        getOptionLabel: (opt) => (opt ? transEnum(opt, ComplianceStatusTranslations) : t`Unknown`),
        cell: ({ row, getValue }) => {
            if (row.original.type === 'row') {
                const value = getValue();
                if (!value) {
                    return <EmptyValue />;
                }
                return <ComplianceStatusChip status={value} />;
            } else {
                const part = row.original.fullPart;
                if (
                    isOtsFullPart(part) ||
                    isGenericFullPart(part) ||
                    isOtsComponentFull(part) ||
                    isCustomFullPart(part)
                ) {
                    return <ComplianceStatusChip status={part.rohs_compliant} />;
                }
                return <EmptyValue />;
            }
        },
    },
);
function extractModuleTableDataAecq(moduleTableData: ModuleTableData) {
    if (isAssemblyTableData(moduleTableData)) {
        return moduleTableData.aecqCompliant;
    }
    if (isPcbDesignItem(moduleTableData)) {
        return undefined;
    }
    return moduleTableData.approvedPartOptions.length > 0 ? moduleTableData.aecqCompliant : undefined;
}

const columnAecq = columnHelper.enum(
    (row) => {
        if (row.type === 'row') {
            return extractModuleTableDataAecq(row.moduleTableData);
        }
    },
    {
        id: bomOverviewColumnIds.columnAecq,
        label: () => t`AECQ`,
        size: 120,
        options: Object.values(ComplianceStatus),
        getOptionLabel: (opt) => (opt ? transEnum(opt, ComplianceStatusTranslations) : t`Unknown`),
        cell: ({ row, getValue }) => {
            if (row.original.type === 'row') {
                const value = getValue();
                if (!value) {
                    return <EmptyValue />;
                }
                return <ComplianceStatusChip status={value} />;
            } else {
                const part = row.original.fullPart;
                if (isOtsFullPart(part) || isGenericFullPart(part) || isOtsComponentFull(part)) {
                    return <ComplianceStatusChip status={part.aecq_compliant} />;
                }
                return <EmptyValue />;
            }
        },
    },
);

function extractModuleTableDataLifecycle(moduleTableData: ModuleTableData) {
    if (isAssemblyTableData(moduleTableData)) {
        return moduleTableData.lifecycleStatus;
    }
    if (isPcbDesignItem(moduleTableData)) {
        return undefined;
    }
    if (moduleTableData.specification?.type === PartSpecificationTypes.Custom) {
        const hasApprovedPartOptions = moduleTableData.approvedPartOptions.length > 0;
        return hasApprovedPartOptions ? LifecycleEnum.Unknown : undefined;
    }
    return moduleTableData.lifecycleStatus;
}

const columnLifecycle = columnHelper.enum(
    (row) => {
        if (row.type === 'row') {
            return extractModuleTableDataLifecycle(row.moduleTableData);
        } else {
            const part = row.fullPart;
            return isOtsFullPart(part) || isGenericFullPart(part) || isOtsComponentFull(part)
                ? part.lifecycle_status
                : undefined;
        }
    },
    {
        id: bomOverviewColumnIds.columnLifecycle,
        label: () => t`Lifecycle`,
        size: 100,
        options: Object.values(LifecycleEnum),
        getOptionLabel: (opt) => (opt ? transEnum(opt, lifecycleEnumPublicTranslations) : t`Unknown`),
        cell: ({ row, getValue }) => {
            if (row.original.type === 'row') {
                const value = getValue();
                if (!value) {
                    return <EmptyValue />;
                }
                return <LifecycleChipWithTooltip status={value} />;
            } else {
                const part = row.original.fullPart;
                if (isOtsFullPart(part) || isGenericFullPart(part) || isOtsComponentFull(part)) {
                    return <LifecycleChipWithTooltip status={part.lifecycle_status} />;
                }
                return <EmptyValue />;
            }
        },
    },
);

const columnYearsToEndOfLife = columnHelper.number(
    (row) => {
        if (row.type === 'row') {
            // Using Infinity when there's no value so it's placed at the bottom of all results when comparing
            return row.moduleTableData.yearsToEndOfLife ?? Infinity;
        }
        return Infinity;
    },
    {
        id: bomOverviewColumnIds.columnYearsToEndOfLife,
        label: () => t`Years to EOL`,
        size: 100,
        cell: ({ row }) => {
            if (row.original.type === 'row') {
                const value = row.original.moduleTableData.yearsToEndOfLife;
                return value !== null && typeof value === 'number' ? (
                    `${value} ${plural(value, {
                        one: 'year',
                        other: 'years',
                    })}`
                ) : (
                    <EmptyValue />
                );
            }
            return <EmptyValue />;
        },
    },
);

const RenderEmissionsHeader = () => {
    return (
        <Flexbox alignItems="center" color={colorSystem.neutral[8]}>
            <Trans>kgCO2e</Trans>
            <EmissionsTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </EmissionsTooltip>
        </Flexbox>
    );
};

const columnEmissions = columnHelper.number(
    (row) => {
        if (row.type === 'row') {
            const maxEmissions = row.moduleTableData.emissionData?.product_phase_gwp_in_kg_co2e_max;
            return maxEmissions ? Number(maxEmissions) : 0;
        }
        return findPartEmissions({ part: row.fullPart }).max ?? 0;
    },
    {
        id: bomOverviewColumnIds.columnEmissions,
        label: () => 'kgCO2e',
        header: () => {
            return <RenderEmissionsHeader />;
        },
        size: 140,
        cell: ({ row, sharedContext }) => {
            if (row.original.type === 'row') {
                const moduleTableData = row.original.moduleTableData;
                if (isAssemblyTableData(moduleTableData)) {
                    if (!moduleTableData.emissionData) {
                        return <EmptyValue />;
                    }
                    const hasIncompleteEmissionsData = moduleTableData.emissionData.kind === 'Gte';
                    const min = Number(moduleTableData.emissionData.product_phase_gwp_in_kg_co2e_min);
                    const max = Number(moduleTableData.emissionData.product_phase_gwp_in_kg_co2e_max);
                    return (
                        <Tooltip
                            title={
                                hasIncompleteEmissionsData
                                    ? t`Carbon footprint expected to be higher. There is data missing for some BOM items.`
                                    : ''
                            }
                        >
                            <span>
                                <EmissionsView min={min} max={max} hasIncompleteData={hasIncompleteEmissionsData} />
                            </span>
                        </Tooltip>
                    );
                }
                if (isPcbDesignItem(moduleTableData)) {
                    return (
                        <ErrorBoundary
                            fallbackRender={() => {
                                return <>-</>;
                            }}
                        >
                            <PcbEmissionsView assemblyId={sharedContext.assemblyId} />
                        </ErrorBoundary>
                    );
                }
                if (isBomItemTableData(moduleTableData)) {
                    const { emissionData } = moduleTableData;
                    if (!emissionData) {
                        return <EmptyValue />;
                    }
                    const min = Number(emissionData.product_phase_gwp_in_kg_co2e_min);
                    const max = Number(emissionData.product_phase_gwp_in_kg_co2e_max);

                    return <EmissionsView min={min} max={max} />;
                }
                return <EmptyValue />;
            } else {
                const parent = row.getParentRow()?.original;
                if (parent?.type === 'row' && isBomItemTableData(parent.moduleTableData)) {
                    const bomItem = parent.moduleTableData;
                    const part = row.original.fullPart;
                    if (!bomItem.quantity || bomItem.designator.length === 0) {
                        return <EmptyValue />;
                    }
                    const quantity = bomItem.designator.length * bomItem.quantity.quantity;
                    return <PartEmissionsView part={part} quantity={quantity} />;
                }
                return <EmptyValue />;
            }
        },
    },
);

const DesignItemComments = ({ bomItem, rfqId }: { bomItem: BomItemTableData; rfqId: string }) => {
    const designItemIds = bomItem.individualDesignItems.flatMap(({ id }) => id);

    const { openDrawer } = useCommunicationsDrawer({
        rfqId,
        threads: [
            {
                commentType: 'DesignItem',
                category: 'Internal',
                typeIds: designItemIds,
            },
            {
                commentType: 'DesignItem',
                category: 'Public',
                typeIds: designItemIds,
            },
        ],
    });

    return (
        <TertiaryIconButton onClick={() => openDrawer()} style={{ minWidth: 'fit-content' }} size="medium">
            {hasUnresolvedComments(bomItem) ? (
                <MarkUnreadChatAltOutlined fontSize="inherit" />
            ) : (
                <ChatOutlined fontSize="inherit" />
            )}
        </TertiaryIconButton>
    );
};

const columnComments = columnHelper.enum(
    (row) => {
        if (row.type === 'row') {
            if (hasResolvedComments(row.moduleTableData)) {
                return CommentsEnum.Resolved;
            }
            if (hasUnresolvedComments(row.moduleTableData)) {
                return CommentsEnum.Unresolved;
            }
        }
    },
    {
        id: bomOverviewColumnIds.columnComments,
        label: () => t`Comments`,
        size: 80,
        options: Object.values(CommentsEnum),
        getOptionLabel: (opt) => (opt ? transEnum(opt, CommentsTranslations) : t`Unknown`),
        enableOnRowClick: false,
        enableSorting: false,
        align: 'center',
        enableHiding: false,
        cell: ({ row }) => {
            if (row.original.type === 'row') {
                const moduleTableData = row.original.moduleTableData;
                if (isAssemblyTableData(moduleTableData)) {
                    return <EmptyValue />;
                }
                const rfqId = moduleTableData.rfqId;
                return isBomItemTableData(moduleTableData) && hasComments(moduleTableData) && rfqId ? (
                    <DesignItemComments bomItem={moduleTableData} rfqId={rfqId} />
                ) : (
                    <EmptyValue />
                );
            }
            return <EmptyValue />;
        },
    },
);

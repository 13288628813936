import { GlobalField, ImporterField, ParseResult } from '../types';
import { parseBoolean } from './parseBoolean';
import { parseCurrency } from './parseCurrency';
import { parseCustomerNumberOrName } from './parseCustomerNumberOrName';
import { parseDateString } from './parseDateString';
import { parseDeliveryStartDate } from './parseDeliveryStartDate';
import { parseEmail } from './parseEmail';
import { parseEnum } from './parseEnum';
import { parseIdentifier } from './parseIdentifier';
import { parseIpn } from './parseIpn';
import { parseLanguage } from './parseLanguage';
import { parseLeadTime } from './parseLeadTime';
import { parseManufacturerName } from './parseManufacturerName';
import { parseMonetaryValue } from './parseMonetaryValue';
import { parseNotes } from './parseNotes';
import { parseNumber } from './parseNumber';
import { parsePackaging } from './parsePackaging';
import { parsePriceType } from './parsePriceType';
import { parseQuantity } from './parseQuantity';
import { parseSiteNumberOrName } from './parseSiteNumberOrName';
import { parseString } from './parseString';
import { parseSupplierNumber } from './parseSupplierNumber';

/**
 * IMPORTANT: Make sure this is in sync with the `FieldType` in [types.ts](../types.ts).
 */
const parserRegistry = {
    boolean: parseBoolean,
    currency: parseCurrency,
    'customer.number-or-name': parseCustomerNumberOrName,
    'date-string': parseDateString,
    'delivery-start-date': parseDeliveryStartDate,
    email: parseEmail,
    enum: parseEnum,
    identifier: parseIdentifier,
    ipn: parseIpn,
    language: parseLanguage,
    leadTimeWeeks: parseLeadTime,
    'manufacturer.name': parseManufacturerName,
    monetaryValue: parseMonetaryValue,
    notes: parseNotes,
    number: parseNumber,
    packaging: parsePackaging,
    priceType: parsePriceType,
    quantity: parseQuantity,
    'site.number-or-name': parseSiteNumberOrName,
    string: parseString,
    'supplier.number': parseSupplierNumber,
} as const;

export type ParsedType<T extends ParserType> =
    ReturnType<(typeof parserRegistry)[T]> extends Promise<ParseResult<infer U>> ? U : never;

type ParserType = keyof typeof parserRegistry;

type ParserOptions<T extends ParserType> = Parameters<(typeof parserRegistry)[T]>[1];

export type ParserConfig<T extends ParserType> = {
    readonly type: T;
    readonly options: ParserOptions<T>;
};

export type AllParserConfigs =
    // combinato-like
    | ParserConfig<'enum'>
    // primitives
    | ParserConfig<'boolean'>
    | ParserConfig<'date-string'>
    | ParserConfig<'email'>
    | ParserConfig<'number'>
    | ParserConfig<'string'>
    | ParserConfig<'identifier'>
    // domain
    | ParserConfig<'currency'>
    | ParserConfig<'ipn'>
    | ParserConfig<'language'>
    | ParserConfig<'leadTimeWeeks'>
    | ParserConfig<'manufacturer.name'>
    | ParserConfig<'monetaryValue'>
    | ParserConfig<'notes'>
    | ParserConfig<'packaging'>
    | ParserConfig<'quantity'>
    | ParserConfig<'supplier.number'>
    | ParserConfig<'delivery-start-date'>
    | ParserConfig<'customer.number-or-name'>
    | ParserConfig<'supplier.number'>
    | ParserConfig<'site.number-or-name'>
    | ParserConfig<'priceType'>;

export function instantiateParser(
    config: AllParserConfigs,
    field: ImporterField | GlobalField,
): (cells: string[]) => Promise<ParseResult<ParsedType<typeof config.type>>> {
    const parserFunc = parserRegistry[config.type];
    return (cells: string[]) => {
        if (cells.length === 0 && field.required) {
            throw new Error(`Field ${field.label} is required but no value was provided`);
        }
        return parserFunc(cells, config.options as any, field as any);
    };
}

/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { formatLongDateTime, formatToIso8601Date } from '@luminovo/commons';
import { ExtractRequestBody, QuantityUnit } from '@luminovo/http-client';
import { UniversalImporter } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { route } from '../../utils/routes';
import { formatError } from '../Error/formatError';

const getDeliveryDates = (start: string, end: string | undefined): { start: string; end: string } => {
    const startDate = formatToIso8601Date(start);
    const endDate = formatToIso8601Date(end);
    return {
        start: startDate,
        end: end && end.length !== 0 ? endDate : startDate,
    };
};

const formatProductionStartDate = (date: string | undefined): string | undefined => {
    if (date === '' || date === undefined) {
        return undefined;
    }
    return formatToIso8601Date(date);
};

export function DemandImporterPage() {
    const { enqueueSnackbar } = useSnackbar();
    const { data: customers } = useHttpQuery(
        'GET /customers',
        {},
        { suspense: true, refetchOnWindowFocus: true, select: (res) => res.data },
    );
    const { data: sites } = useHttpQuery(
        'GET /organization-settings/sites',
        {},
        {
            suspense: true,
            refetchOnWindowFocus: true,
            select: (res) => res.data.sites,
        },
    );
    const { mutateAsync: importDemand } = useHttpMutation('POST /demand/import', { snackbarMessage: null });
    const history = useHistory();
    const defaultContext = `Demand - ${formatLongDateTime(new Date().toString())}`;
    return (
        <UniversalImporter
            title={t`Import demand`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(t`Import successful`, {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
                history.goBack();
            }}
            onImportBatch={async (batch, globalFields) => {
                const demandItems: ExtractRequestBody<'POST /demand/import'>['items'] = batch.map((row) => {
                    const { start: delivery_start_date, end: delivery_end_date } = getDeliveryDates(
                        row.data.delivery_start_date,
                        row.data.delivery_end_date,
                    );
                    return {
                        type: 'gross',
                        delivery_start_date,
                        delivery_end_date,
                        internal_part_number: {
                            value: row.data.ipn,
                        },
                        production_start_date: formatProductionStartDate(row.data.production_start_date),
                        quantity: {
                            quantity: row.data.quantity,
                            unit: QuantityUnit.Pieces,
                        },
                        recipient_site_id:
                            row.data['recipient-site-id'].length === 0 ? undefined : row.data['recipient-site-id'],
                        supplier_site_id:
                            row.data['supplier-site-id'].length === 0 ? undefined : row.data['supplier-site-id'],
                        end_customer_id:
                            row.data['customer-id'].length === 0 || row.data['customer-id'] === '-'
                                ? undefined
                                : row.data['customer-id'],
                    };
                });

                return importDemand({
                    requestBody: {
                        items: demandItems,
                        context:
                            globalFields && globalFields.length > 0 && globalFields[0].value?.id
                                ? globalFields[0].value.id
                                : defaultContext,
                    },
                })
                    .then((results) => {
                        return batch.map((batchItem) => {
                            const resultItem = results.find(
                                (resultItem) => batchItem.data.ipn === resultItem.internal_part_number.value,
                            );

                            if (!resultItem) {
                                return {
                                    success: false as const,
                                    message: t`Unknown error`,
                                };
                            }

                            if (resultItem.status > 299) {
                                return {
                                    success: false as const,
                                    message: resultItem.description ?? t`Unknown error`,
                                };
                            }

                            return {
                                success: true as const,
                            };
                        });
                    })
                    .catch((error) => {
                        return batch.map((row) => {
                            return {
                                success: false as const,
                                message: formatError(error),
                            };
                        });
                    });
            }}
            config={{
                globalFields: [
                    {
                        id: 'context' as const,
                        label: t`Context`,
                        description: t`The context in which demands are imported or managed, helping to distinguish between different imports.`,
                        value: { id: defaultContext, label: t`Context` },
                        required: true,
                        parser: { type: 'string', options: { trim: true } },
                    },
                ],
                fields: [
                    {
                        id: 'ipn' as const,
                        columnIndices: [],
                        required: true,
                        parser: { type: 'ipn', options: { ipns: [] } },
                        label: t`IPN`,
                        description: t`The internal part number`,
                    },
                    {
                        id: 'quantity' as const,
                        columnIndices: [],
                        required: true,
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`Quantity`,
                    },
                    {
                        id: 'delivery_start_date' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'delivery-start-date', options: {} },
                        label: t`Delivery start date`,
                        description: t`The delivery start date in the format "YYYY-MM-DD".`,
                    },
                    {
                        id: 'delivery_end_date' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'date-string', options: { trim: true } },
                        label: t`Delivery end date`,
                        description: t`The delivery end date in the format "YYYY-MM-DD". If not specified, the delivery start date will be used.`,
                    },
                    {
                        id: 'production_start_date' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'date-string', options: { trim: true } },
                        label: t`Production start date`,
                    },
                    {
                        id: 'supplier-site-id' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'site.number-or-name', options: { sites: sites ?? [] } },
                        label: t`Supplier site`,
                        description: t`The internal site number or name of the supplier site`,
                    },
                    {
                        id: 'recipient-site-id' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'site.number-or-name', options: { sites: sites ?? [] } },
                        label: t`Recipient site`,
                        description: t`The recipient site number or name`,
                    },
                    {
                        id: 'customer-id' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'customer.number-or-name', options: { customers: customers ?? [] } },
                        label: t`End customer`,
                        description: t`The customer number for the final recipient of the demand, regardless of the intermediary recipient of the demand which may be sites of suppliers.`,
                    },
                ],
            }}
        />
    );
}
